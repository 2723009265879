/**
 *
 *  About Section Style
 *
 *  @package ohkimur_layout
 *
 **/

section.about-section {
    h2 {
        margin: 0.5em 0;
    }
}
